var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.currentUser.type == 'CEO' ? _c('div', {
    staticClass: "min-h-screen api flex-1 mt-1 bg-gray-100 px-4 py-3 items-center"
  }, [_c('div', {
    staticClass: "bg-white w-full rounded-lg shadow p-10",
    staticStyle: {
      "height": "99%",
      "border": "1px solid white",
      "page-break-after": "avoid",
      "page-break-before": "avoid"
    },
    attrs: {
      "id": "genarateToken"
    }
  }, [_c('div', {
    staticClass: "flex justify-between"
  }, [_c('h2', {
    attrs: {
      "id": "h_linking_in_api_documentation"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("Documentation API")) + ": ")]), _c('div', {
    staticClass: "relative mr-4 inline-block"
  }, [_c('div', {
    staticClass: "text-gray-500 cursor-pointer w-10 h-10 rounded-full bg-gray-100 hover:bg-gray-300 inline-flex items-center justify-center"
  }, [_c('svg', {
    staticClass: "icon icon-tabler icon-tabler-printer",
    attrs: {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "stroke-width": "2",
      "stroke": "currentColor",
      "fill": "none",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    },
    on: {
      "click": _vm.generatePdf
    }
  }, [_c('rect', {
    attrs: {
      "x": "0",
      "y": "0",
      "width": "24",
      "height": "24",
      "stroke": "none"
    }
  }), _c('path', {
    attrs: {
      "d": "M17 17h2a2 2 0 0 0 2 -2v-4a2 2 0 0 0 -2 -2h-14a2 2 0 0 0 -2 2v4a2 2 0 0 0 2 2h2"
    }
  }), _c('path', {
    attrs: {
      "d": "M17 9v-4a2 2 0 0 0 -2 -2h-6a2 2 0 0 0 -2 2v4"
    }
  }), _c('rect', {
    attrs: {
      "x": "7",
      "y": "13",
      "width": "10",
      "height": "8",
      "rx": "2"
    }
  })])]), _c('div', {
    staticClass: "z-40 shadow-lg text-center w-32 block absolute right-0 top-0 p-2 mt-12 rounded-lg bg-gray-800 text-white text-xs",
    staticStyle: {
      "display": "none"
    },
    attrs: {
      "x-show.transition": "showTooltip"
    }
  }, [_vm._v(" Print this invoice! ")])])]), _vm._m(0)])]) : _vm._e();

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "p-5"
  }, [_c('hr', {
    staticClass: "divide_hr"
  }), _c('h2', {
    attrs: {
      "id": "link_same_file_default"
    }
  }, [_vm._v("Token Généreration")]), _c('section', [_c('div', {
    staticClass: "border border-gray-700 border-dashed p-4"
  }, [_c('p', {
    staticClass: "marker"
  }, [_c('span', {
    staticClass: "circle-range"
  }), _c('strong', [_vm._v("Get authorisation token:")])]), _c('pre', [_vm._v("https://api-santiago.speedliv.com/api/users/apilogin")]), _c('p', {
    staticClass: "marker"
  }, [_c('span', {
    staticClass: "circle-range"
  }), _c('strong', [_vm._v("Query params:")])]), _c('pre', {
    staticClass: "NoteP"
  }, [_vm._v("                      {\n                      "), _c('span', {
    staticClass: "objet"
  }, [_vm._v(" \n                         email: \""), _c('span', {
    staticClass: "text-red-500"
  }, [_vm._v("........")]), _vm._v("\","), _c('br'), _vm._v("\n                         password: \""), _c('span', {
    staticClass: "text-red-500"
  }, [_vm._v("........")]), _vm._v("\"\n                      ")]), _vm._v("\n                    }\n              ")]), _c('p', {
    staticClass: "marker"
  }, [_c('span', {
    staticClass: "circle-range"
  }), _c('strong', [_vm._v("Response Sample:")]), _c('span', {
    staticClass: "block"
  }, [_vm._v("HTTP/1.1 200")])]), _c('pre', {
    staticClass: "NoteP"
  }, [_vm._v("                {\n                  "), _c('span', {
    staticClass: "objet"
  }, [_vm._v("  token : \""), _c('span', {
    staticClass: "text-red-500"
  }, [_vm._v("xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx")]), _vm._v("\" ")]), _vm._v("\n                }\n              ")])]), _c('div', {
    staticClass: "border border-gray-700 border-dashed p-4 mt-2"
  }, [_c('p', {
    staticClass: "marker"
  }, [_c('span', {
    staticClass: "circle-range"
  }), _c('strong', [_vm._v("Header params:")])]), _c('pre', [_c('strong', [_vm._v("Content-Type ")]), _vm._v(": application/json;charset=utf-8"), _c('br'), _vm._v("\n                 "), _c('strong', [_vm._v("x-auth-token ")]), _vm._v(": {token}\n               ")])]), _c('div', {
    staticClass: "border border-gray-700 border-dashed p-4 mt-2"
  }, [_c('p', {
    staticClass: "marker"
  }, [_c('span', {
    staticClass: "circle-range"
  }), _c('strong', [_vm._v("Base URL creation:")])]), _c('pre', [_vm._v("https://api-santiago.speedliv.com/api/orders/apicreate")]), _c('p', {
    staticClass: "marker"
  }, [_c('span', {
    staticClass: "circle-range"
  }), _c('strong', [_vm._v("Post DATA:")])]), _c('pre', {
    staticClass: "NoteP"
  }, [_vm._v("                      {\n                      "), _c('span', {
    staticClass: "objet"
  }, [_vm._v(" \n                      orderId:{ type: String, required: true, maxlength: 250 }, "), _c('span', {
    staticClass: "comment"
  }, [_vm._v("//order id")]), _c('br'), _vm._v("\n                      source:{ type: String, required: true, maxlength: 300 }, "), _c('span', {
    staticClass: "comment"
  }, [_vm._v("//source de trafic")]), _c('br'), _vm._v("\n                      fullName: { type: String, required: true, maxlength: 250 },"), _c('span', {
    staticClass: "comment"
  }, [_vm._v("//customer name (*)")]), _c('br'), _vm._v("\n                      phone: { type: String, required: true, maxlength: 100 },"), _c('span', {
    staticClass: "comment"
  }, [_vm._v("(*)")]), _c('br'), _vm._v("\n                      country: { type: String, required: true, maxlength: 2 }, "), _c('span', {
    staticClass: "comment"
  }, [_vm._v("//country code")]), _c('br'), _vm._v("\n                      city: { type: String, maxlength: 50 },"), _c('br'), _vm._v(" \n                      address: { type: String, required: true, maxlength: 250 },"), _c('br'), _vm._v(" \n                      items:[ "), _c('span', {
    staticClass: "comment"
  }, [_vm._v("//products array info in order in JSON")]), _vm._v(" "), _c('br'), _vm._v("\n                      "), _c('span', {
    staticClass: "objet-pr"
  }, [_vm._v(" {")]), _vm._v("\n                      "), _c('ul', [_c('br'), _vm._v("\n                        "), _c('li', [_vm._v(" name: { type: String, required: true, maxlength: 250 },"), _c('span', {
    staticClass: "comment"
  }, [_vm._v("//Nom de produit (*)")])]), _vm._v("\n                        "), _c('li', [_vm._v(" code: { type: String, required: true, maxlength: 100 },"), _c('span', {
    staticClass: "comment"
  }, [_vm._v("//Code de produit (*)")]), _vm._v(" ")]), _vm._v("\n                        "), _c('li', [_vm._v(" quantity: { type: Number, required: true },"), _c('span', {
    staticClass: "comment"
  }, [_vm._v("(*)")]), _vm._v(" ")]), _vm._v("\n                        "), _c('li', [_vm._v(" price: { type: Number, required: true }"), _c('span', {
    staticClass: "comment"
  }, [_vm._v("//Unit price (*)")])]), _vm._v("\n                      ")]), _c('br'), _vm._v("\n                      "), _c('span', {
    staticClass: "objet-pr"
  }, [_vm._v(" }")]), _vm._v("\n                      ],"), _c('br'), _vm._v("\n                      total: { type: Number, required: true }"), _c('span', {
    staticClass: "comment"
  }, [_vm._v("//Total amount (*)")]), _c('br'), _vm._v("\n                      ")]), _vm._v("\n                    }\n              ")]), _c('p', {
    staticClass: "marker"
  }, [_c('span', {
    staticClass: "circle-range"
  }), _c('strong', [_vm._v("Response Sample:")])]), _c('pre', {
    staticClass: "NoteP"
  }, [_vm._v("                {\n                  "), _c('span', {
    staticClass: "objet"
  }, [_vm._v("  id_order : 65855cc1f55c83e0f2bb8561 ")]), _vm._v("\n                }\n              ")])]), _c('div', {
    staticClass: "border border-gray-700 border-dashed p-4 mt-2"
  }, [_c('p', {
    staticClass: "marker"
  }, [_c('span', {
    staticClass: "circle-range"
  }), _c('strong', [_vm._v("Base URL update:")])]), _c('pre', [_vm._v("https://api-santiago.speedliv.com/api/orders/apiupdate?id={id_order}")]), _c('p', {
    staticClass: "marker"
  }, [_c('span', {
    staticClass: "circle-range"
  }), _c('strong', [_vm._v("Post DATA:")])]), _c('pre', {
    staticClass: "NoteP"
  }, [_vm._v("                      {\n                      "), _c('span', {
    staticClass: "objet"
  }, [_vm._v(" \n                        items:[ "), _c('span', {
    staticClass: "comment"
  }, [_vm._v("//products array info in order in JSON")]), _vm._v(" "), _c('br'), _vm._v("\n                        "), _c('span', {
    staticClass: "objet-pr"
  }, [_vm._v(" {")]), _vm._v("\n                      "), _c('ul', [_c('br'), _vm._v("\n                        "), _c('li', [_vm._v(" name: { type: String, required: true, maxlength: 250 },"), _c('span', {
    staticClass: "comment"
  }, [_vm._v("//Nom de produit (*)")])]), _vm._v("\n                        "), _c('li', [_vm._v(" code: { type: String, required: true, maxlength: 100 },"), _c('span', {
    staticClass: "comment"
  }, [_vm._v("//Code de produit (*)")]), _vm._v(" ")]), _vm._v("\n                        "), _c('li', [_vm._v(" quantity: { type: Number, required: true },"), _c('span', {
    staticClass: "comment"
  }, [_vm._v("(*)")]), _vm._v(" ")]), _vm._v("\n                        "), _c('li', [_vm._v(" price: { type: Number, required: true }"), _c('span', {
    staticClass: "comment"
  }, [_vm._v("//Unit price (*)")])]), _vm._v("\n                      ")]), _c('br'), _vm._v("\n                      "), _c('span', {
    staticClass: "objet-pr"
  }, [_vm._v(" }")]), _vm._v("\n                      ],"), _c('br'), _vm._v("\n                      total: { type: Number, required: true }"), _c('span', {
    staticClass: "comment"
  }, [_vm._v("//Total amount (*)")]), _c('br'), _vm._v("\n                      ")]), _vm._v("\n                    }\n              ")]), _c('p', {
    staticClass: "marker"
  }, [_c('span', {
    staticClass: "circle-range"
  }), _c('strong', [_vm._v("Response Sample:")])]), _c('pre', {
    staticClass: "NoteP"
  }, [_vm._v("                {\n                  "), _c('span', {
    staticClass: "objet"
  }, [_vm._v("  id_order : 65855cc1f55c83e0f2bb8561 ")]), _vm._v("\n                }\n              ")])]), _c('p', {
    staticClass: "Note"
  }, [_c('strong', [_vm._v("Note")]), _vm._v(": Country ça sera le country code (par exemple Maroc: MA ,Côte d'Ivoire : CI ...) ")]), _c('p', {
    staticClass: "Note"
  }, [_c('strong', [_vm._v("Note")]), _vm._v(": Le nom du produit doit être le même que le nom crée en speedliv ")])])]);

}]

export { render, staticRenderFns }